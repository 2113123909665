import React from 'react';
import globalStyle from '../component/PageLayout.module.css';
import downloadStyle from './Download.module.css';

function Download() {

    const handleDownload = () => {
        alert("Downloads will be available soon");
    }

    const handleDownloadPC = () => {
        alert("Download will be avialable soon");
    }
    
  return (
    <div className={globalStyle.firstelement}>
      <div className={downloadStyle.container}>
        <div className={downloadStyle.optionContainer}>
          <h2>IOS</h2>
          <p>Download the app for iOS devices</p>
          <button href="ios-download-link" className={downloadStyle.downloadButton}>
            Download
          </button>
        </div>
        <div className={downloadStyle.optionContainer}>
          <h2>Windows</h2>
          <p>Download the app for Windows</p>
          <button onClick={handleDownloadPC} className={downloadStyle.downloadButton}>
            Download
          </button>
        </div>
        <div className={downloadStyle.optionContainer}>
          <h2>Linux</h2>
          <p>Download the app for Linux</p>
          <button href="linux-download-link" className={downloadStyle.downloadButton}>
            Download
          </button>
        </div>
        <div className={downloadStyle.optionContainer}>
          <h2>iPhone</h2>
          <p>Download the app for iPhone</p>
          <button onClick={handleDownload} className={downloadStyle.storeButton}>
            App Store
          </button>
        </div>
        <div className={downloadStyle.optionContainer}>
          <h2>Android</h2>
          <p>Download the app for Android</p>
          <button onClick={handleDownload} className={downloadStyle.storeButton}>
            Play Store
          </button>
        </div>
      </div>
    </div>
  );
}

export default Download;