import React from 'react';
import styles from './Home.module.css';
import { useNavigate } from 'react-router-dom';
import globalstyle from '../component/PageLayout.module.css'

function Home() {
  const navigate = useNavigate();

  const handleDownloads= () => {
    navigate("/download")
  }

  return (
    <div className={globalstyle.firstelement}>
        <h1 className={globalstyle.notification}>Website and application still in progress, stay tuned!</h1>
      <div className={styles.container}>
        <div className={styles.paragraphContainer}>
          <p className={styles.paragraph}>
            <span className={styles.highlight}>
              Discover the Ultimate App for Travel Agencies - Manage, Boost, and Optimize Your Business
            </span>
            <br />
            <br />
            Are you a travel agency searching for an efficient solution to manage your finances, sales, and agents? Look no further! Introducing our groundbreaking app - ToursTrust - the central hub for financial transparency, sales monitoring, and seamless tour management.
          </p>
        </div>
        <div className={styles.paragraphContainer}>
          <p className={styles.paragraph}>
            <span className={styles.highlight}>
              Sales Agents: Boost Your Earnings with the App
            </span>
            <br />
            <br />
            As a sales agent, it's crucial to stay on top of your sales and commissions. With our app, you'll have full control over your finances at your fingertips. Effortlessly record sales and track how much commission you've earned. Whether you're on the go or in the office, our innovative offline mode enables you to access product information anytime, anywhere, empowering you to make sales and maximize your earnings. Seize this freedom and unlock your revenue potential!
          </p>
        </div>
        <div className={styles.paragraphContainer}>
          <p className={styles.paragraph}>
            <span className={styles.highlight}>
              Operators: Gain Insights and Optimize Your Products
            </span>
            <br />
            <br />
            As a proud owner of tours, our app provides you with complete oversight of your business. Effectively manage your products by gaining visibility into daily, weekly, and monthly earnings. This invaluable data allows you to optimize your tours and elevate their success. Furthermore, you have the power to select which travel agencies are authorized to sell your products. Choose wisely, ensuring that only the finest agencies offer your premium tours. Take the helm of your enterprise and steer it towards triumph!
          </p>
        </div>
        <div className={styles.paragraphContainer}>
          <p className={styles.paragraph}>
            <span className={styles.highlight}>
              Captivating Experiences - Your Tours as Lasting Memories
            </span>
            <br />
            <br />
            Imagine tourists from all over the world experiencing unforgettable adventures through your tours. Whether it's an exhilarating dive excursion on a boat, a breathtaking journey through a national park, or any other remarkable experience, your tours create enduring memories for your customers. And with our app, marketing and managing these tours have never been easier.
          </p>
        </div>
        <div className={styles.paragraphContainer}>
          <p className={styles.paragraph}>
            Don't wait any longer! Download the ToursTrust app now and transform your business into a more profitable venture. Organize your finances, boost your sales, and optimize your tours. We promise you a seamless experience that will propel your travel agency to new heights.
            <br />
            <br />
            Are you ready to take control? Download the ToursTrust app today and embark on the journey to a successful and flourishing business!
          </p>
          <button onClick={handleDownloads}>Downloads</button>
        </div>
      </div>
    </div>
  );
}

export default Home;