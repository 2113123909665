import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";
import PageLayout from "./component/PageLayout";
import About from './page/About';
import Download from './page/Download';
import Service from './page/Service';
import Data from './page/data';
import Home from './page/Home';
import Team from './page/Team';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path=''>
          <Route path='' element={<PageLayout/>}>
            <Route index='home' element={<Home/>}/>
            <Route path='welcome' element={<Service/>}/>
            <Route path='about' element={<About/>}/>
            <Route path='download' element={<Download/>}/>
            <Route path='team' element={<Team/>}/>
            <Route path='data/:id' element={<Data/>}/>
          </Route>
        </Route>
    )
)

function App() {
    return <RouterProvider router={router}/>
}

export default App;