import { Outlet } from "react-router-dom";
import Header from "./Header";

function PageLayout(){
    return(
        <div>
            <header>
                <Header/>
            </header>
            <Outlet></Outlet>
        </div>
    )
}

export default PageLayout;