import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import './ProfileCard.css';
import globalstyle from './PageLayout.module.css';

function ProfileCard({ profileData }) {
  return (
    <div className={globalstyle.firstelement}>
        <div className="profile-container">
            <div className="profile">
                <div className="profile-picture">
                    <img src={profileData.imageurl} alt="Profile Picture" />
                </div>
                <div className="profile-details">
                    <h1 className="title">{profileData.name}</h1>
                    <h2 className="name">{profileData.title}</h2>
                    <div className="contact">
                        <div className="contact-item">
                            <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                            <a href={`mailto:${profileData.email}`} className="contact-link">{profileData.email}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  );
}

export default ProfileCard;
