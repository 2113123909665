import { db } from "./config";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";

const COLLECTION_NAME = "team";
const col = collection(db, COLLECTION_NAME);

const memberapi = {
  async readAll() {
    const querySnapshot = await getDocs(col);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  },
  async read(id) {
    const docRef = doc(db, COLLECTION_NAME, id);
    const document = await getDoc(docRef);
    return { id: document.id, ...document.data() };
  },
};

export default memberapi;
