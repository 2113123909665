import { useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import logoTT from '../images/logoToursTrust.png';

function Header() {
    const navigate = useNavigate();

    const handleNavigation = (goto) => {
        navigate(goto);
    };

    return (
        <nav className={styles.nav}>
            <div className={styles.logo}>
                <img src={logoTT} alt="ToursTrust Logo" />
            </div>
            <h1 className={styles.title}>ToursTrust</h1>
            <div className={styles.navItems}>
                <button
                    className={styles.button}
                    onClick={() => handleNavigation('/')}
                >
                    Home
                </button>
                <button
                    className={styles.button}
                    onClick={() => handleNavigation('/download')}
                >
                    Download
                </button>
                <button
                    className={styles.button}
                    onClick={() => handleNavigation('/team')}
                >
                    Team
                </button>
            </div>
        </nav>
    );
}

export default Header;